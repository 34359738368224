<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :href="$router.resolve({ name: 'admin.institutions.create', query: { actAs: $route.query.actAs } }).href"
              target="_blank"
            ><i data-feather="plus" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="institutions"
              :sort-mapping="sortMapping"
              :title="'institutions'"
              :total="institutionsTotal"
              :fetch-path="'institutions/fetch'"
              :filter-path="'institutions/filter'"
              :export-path="'institutions/export'"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        :href="$router.resolve({ name: 'admin.institutions.edit', params: { id: field.id }, query: { actAs: $route.query.actAs } }).href"
                        target="_blank"
                      >
                        <i data-feather="edit-3" />
                        Edit institution
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="field.agreement && field.files.filter(e => e.current).length > 0"
                        :href="field.files.filter(e => e.current)[0].url"
                        target="_blank"
                      >
                        <i data-feather="file" />
                        View agreement file
                      </b-dropdown-item>
                      <b-dropdown-item @click="showDeleteModal(field.id)">
                        <i data-feather="trash-2" />
                        Delete
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item
                        :href="`${$router.resolve({name: 'admin.users.index'}).href}?host=${field.id}`"
                        target="_blank"
                      >
                        <i data-feather="eye" />
                        Show ICREAs
                      </b-dropdown-item>
                      <b-dropdown-item
                        :href="`${$router.resolve({name: 'admin.institution-contacts.index'}).href}?host=${field.id}`"
                        target="_blank"
                      >
                        <i data-feather="eye" />
                        Show contacts
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </td>
              </template>
              <template #customfilters>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Name</label>
                  <input
                    v-model="filters.name"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Name: ${$event.target.value}`, value: `${$event.target.value}` }, 'name')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Acronym</label>
                  <input
                    v-model="filters.code"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Acronym: ${$event.target.value}`, value: `${$event.target.value}` }, 'code')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >City</label>
                  <input
                    v-model="filters.city"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `City: ${$event.target.value}`, value: `${$event.target.value}` }, 'city')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Country</label>
                  <input
                    v-model="filters.country"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Country: ${$event.target.value}`, value: `${$event.target.value}` }, 'country')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Phone</label>
                  <input
                    v-model="filters.phone"
                    type="number"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Phone: ${$event.target.value}`, value: `${$event.target.value}` }, 'phone')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Email</label>
                  <input
                    v-model="filters.email"
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter({ customLabel: true, name: `Email: ${$event.target.value}`, value: `${$event.target.value}` }, 'email')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Type</label>
                  <v-select
                    v-model="filters.type"
                    label="name"
                    :options="types"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'institutions/fetchTypes')"
                    @input="saveFilter({ customLabel: true, name: `Type: ${$event ? $event.name : ''}`, value: $event }, 'type')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Agreement</label>
                  <select
                    v-model="filters.agreement"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Agreement: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'agreement')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Public/Private</label>
                  <select
                    v-model="filters.visibility"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Public/Private: ' + ($event.target.value === 'true' ? 'Private' : ($event.target.value === '' ? 'All' : 'Public')), value: $event.target.value }, 'visibility')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="false"
                    >
                      Public
                    </option>
                    <option
                      value="true"
                    >
                      Private
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Active</label>
                  <select
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: `Active: ${$event.target.value === 'true' ? 'Yes' : ($event.target.value === 'false' ? 'No' : 'All')}`, value: `${$event.target.value}` }, 'active')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Catalan</label>
                  <select
                    v-model="filters.catalan"
                    class="form-select"
                    @input="saveFilter({ customLabel: true, name: 'Catalan: ' + ($event.target.value === 'true' ? 'Yes' : ($event.target.value === '' ? 'All' : 'No')), value: $event.target.value }, 'catalan')"
                  >
                    <option
                      value=""
                      selected
                    >
                      All
                    </option>
                    <option
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    vSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Acronym',
          checked: true,
          order: 2,
        },
        {
          name: 'City',
          checked: true,
          order: 3,
        },
        {
          name: 'Country',
          checked: true,
          order: 4,
        },
        {
          name: 'Phone',
          checked: true,
          order: 5,
        },
        {
          name: 'Mail',
          checked: true,
          order: 6,
        },
        {
          name: 'Head',
          checked: true,
          order: 7,
        },
        {
          name: 'Host type',
          checked: true,
          order: 8,
        },
        {
          name: 'Public',
          checked: true,
          order: 9,
        },
        {
          name: 'Agreement',
          checked: true,
          order: 10,
        },
        {
          name: 'Catalan',
          checked: true,
          order: 11,
        },
        {
          name: 'Active',
          checked: true,
          order: 12,
        },
        {
          name: 'Web',
          checked: true,
          order: 13,
        },
        // {
        //   name: 'Icreas',
        //   checked: true,
        //   order: 14,
        // },
        {
          name: 'Contacts',
          checked: true,
          order: 15,
        },
      ],
      sortMapping: {
        Name: 'name',
        Acronym: 'code',
        City: 'city',
        Country: 'country',
        Phone: 'head_contact.phone',
        Mail: 'head_contact.email',
        Head: 'head_contact.full_name',
        'Host type': 'type.name',
        Public: 'public',
        Active: 'status_table',
        Agreement: 'has_agreement',
        Catalan: 'is_catalan',
        Web: 'web_table',
        // Icreas: 'icreas',
        Contacts: 'contacts.length',
      },
      title: 'Institutions',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      institutions: 'institutions/institutions',
      institutionsTotal: 'institutions/institutionsTotal',
      types: 'institutions/types',
      hasRelatedData: 'institutions/hasRelatedData',
      filters: 'filters/filters',
    }),
  },
  async mounted() {
    this.saveFilter({ customLabel: true, name: 'Agreement: Yes', value: true }, 'agreement')
    await this.$store.dispatch('institutions/filter', this.$store.getters['filters/filters'])
    await this.$store.dispatch('modals/fetchUserFields', 'institutions')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'institutions',
      })
    }
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if (s.name !== '') {
        await this.$store.dispatch(store, s)
      }
    },
    // async showDeleteModal(id) {
    //   await this.$store.dispatch('modals/saveDeleteData', { name: 'institutions', url: `${Vue.prototype.$groupUrl}/institutions/${id}` })
    //   this.$store.dispatch('modals/showDeleteModal', true)
    // },
    async showDeleteModal(id) {
      const hasRelationDispatch = { name: 'institutions/checkRelations', id }
      await this.$store.dispatch(hasRelationDispatch.name, hasRelationDispatch.id)
      if (!this.hasRelatedData) {
        await this.$store.dispatch('modals/saveDeleteData', { name: 'institutions', url: `${Vue.prototype.$groupUrl}/institutions/${id}` })
        this.$store.dispatch('modals/showDeleteModal', true)
      } else {
        await this.$store.dispatch('modals/saveReorganizeData', {
          deleteUrl: `${Vue.prototype.$groupUrl}/institutions/${id}`,
          deleteFetchName: 'institutions',
          deleteCustomMessage: 'The data has been transferred, do you want to delete this institution?',
          itemId: id,
          itemsFetchName: 'institutions/auxFilter',
          itemsStoreName: 'institutions/institutionsFiltered',
          title: 'institution',
          hasRelationDispatch,
        })
        this.$store.dispatch('modals/showReorganizeModal', true)
      }
    },
  },
}
</script>
